import { toast } from "@ts/utilities/general/toastUtils.ts";

/**
 * Represents the structure of an API error response.
 */
interface ApiError {
    response?: {
        status?: number;
        data?: {
            message?: string;
            error?: string;
        };
    };
    message?: string;
}

/**
 * Display appropriate error notifications based on API response using the toast service.
 * @param {ApiError} error - The error object from the API response.
 * @param {string} fallbackMessage - A fallback message to display if no specific error message is found.
 * @param {string} title - The title of the notification
 */
const displayErrorNotification = (
    error: ApiError,
    fallbackMessage: string,
    title?: string,
): void => {
    const errorMessage = parseErrorMessage(error) || fallbackMessage;
    toast.error(errorMessage, {
        className: "error-toast",
        duration: 5000,
        close: true,
    });
};

/**
 * Apply error styling and messages to form fields.
 * @param {Record<string, string>} errors - An object mapping field names to error messages.
 * @param {HTMLFormElement} form - The form element containing the fields.
 */
const displayFieldErrors = (errors: Record<string, string>, form: HTMLFormElement): void => {
    const fields = form.querySelectorAll<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >("input, select, textarea");
    for (const field of fields) {
        const errorText = errors[field.name];
        const errorSpan =
            field.closest(".field_container")?.querySelector(".error-span") ||
            field.parentElement?.querySelector(".error-span");
        if (errorText) {
            field.classList.add("border-red-500");
            if (errorSpan) {
                errorSpan.classList.remove("hidden");
                errorSpan.innerHTML = errorText;
            }
            // Display a toast for each field error
            toast.error(`${field.name}: ${errorText}`, {
                className: "field-error-toast",
                duration: 3000,
                close: true,
            });
        } else {
            field.classList.remove("border-red-500");
            if (errorSpan) {
                errorSpan.classList.add("hidden");
                errorSpan.innerHTML = "";
            }
        }
    }
};

/**
 * Clear error styling and messages from form fields.
 * @param {HTMLFormElement} form - The form element containing the fields to clear.
 */
const clearFieldErrors = (form: HTMLFormElement): void => {
    const fields = form.querySelectorAll<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >("input:not([type='hidden']), select, textarea");
    for (const field of fields) {
        const errorSpan =
            field.closest(".field_container")?.querySelector(".error-span") ||
            field.parentElement?.querySelector(".error-span");
        field.classList.remove("border-red-500");
        if (errorSpan) {
            errorSpan.classList.add("hidden");
            errorSpan.innerHTML = "";
        }
    }
};

/**
 * Parse error message from API response.
 * @param {ApiError} error - The error object from the API response.
 * @returns {string | undefined} The parsed error message or undefined if no message is found.
 */
const parseErrorMessage = (error: ApiError): string | undefined => {
    const status = error.response?.status;
    switch (status) {
        case 401:
            return "You are not authorized to perform this action.";
        case 403:
            return "You do not have permission to perform this action.";
        case 404:
            return "Oops! Looks like the thing you are looking for does not exist anymore. Please refresh the page.";
        case 429:
            return "You have made too many requests. Please try again later.";
        case 500:
            return "Something is wrong on our end. Please try again later.";
        default:
            return error.response?.data?.message || error.response?.data?.error || error.message;
    }
};

export { displayErrorNotification, displayFieldErrors, clearFieldErrors };
