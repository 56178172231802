import Toastify, { type Options as ToastifyOptions } from "toastify-js";
import "toastify-js/src/toastify.css";

type ToastType = "success" | "error" | "warning" | "info";

/**
 * Extends Toastify options with custom properties for our toast service.
 * @extends Omit<ToastifyOptions, 'text' | 'style'>
 */
interface ToastOptions extends Omit<ToastifyOptions, "text" | "style"> {
    /** The message to display in the toast. */
    message: string;
    /** The type of toast notification. */
    type?: ToastType;
    /** Custom icon to display before the message. */
    icon?: string;
    /** Additional CSS class names to apply to the toast. */
    className?: string;
}

const defaultOptions: Partial<ToastOptions> = {
    type: "info",
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
};

/** Configuration for each toast type, including colors and default icons. */
const typeConfig: Record<
    ToastType,
    { backgroundColor: string; textColor: string; icon: string; fontSize?: string }
> = {
    success: { backgroundColor: "#48d9a4", textColor: "#ffffff", icon: "✅", fontSize: "12px" },
    error: { backgroundColor: "#ff6b6b", textColor: "#ffffff", icon: "❌", fontSize: "12px" },
    warning: { backgroundColor: "#feca57", textColor: "#000000", icon: "⚠️", fontSize: "12px" },
    info: { backgroundColor: "#54a0ff", textColor: "#ffffff", icon: "ℹ️", fontSize: "12px" },
};

/**
 * Displays a toast notification with the given options.
 * @param {ToastOptions} options - The options for the toast notification.
 */
export const showToast = (options: ToastOptions): void => {
    const mergedOptions: ToastOptions = { ...defaultOptions, ...options };
    const { backgroundColor, textColor, icon, fontSize } = typeConfig[mergedOptions.type || "info"];

    const toastifyOptions: ToastifyOptions = {
        ...mergedOptions,
        text: `${mergedOptions.icon || icon}  ${mergedOptions.message}`,
        style: {
            background: backgroundColor,
            color: textColor,
            fontSize: fontSize,
            borderRadius: "0.5rem",
        },
        className: `toastify-${mergedOptions.type} ${mergedOptions.className || ""}`.trim(),
    };

    Toastify(toastifyOptions).showToast();
};

/**
 * Creates a function to show a specific type of toast notification.
 * @param {ToastType} type - The type of toast to create.
 * @returns {Function} A function that takes a message and optional ToastOptions to display toast.
 */
export const createToast =
    (
        type: ToastType,
    ): ((message: string, options?: Omit<ToastOptions, "message" | "type">) => void) =>
    (message: string, options?: Omit<ToastOptions, "message" | "type">): void =>
        showToast({ message, type, ...options });

/**
 * Object containing functions to easily display different types of toast notifications.
 */
export const toast = {
    /**
     * Displays a success toast notification.
     * @param {string} message - The message to display.
     * @param {Omit<ToastOptions, 'message' | 'type'>} [options] - Additional options for the toast.
     */
    success: createToast("success"),

    /**
     * Displays an error toast notification.
     * @param {string} message - The message to display.
     * @param {Omit<ToastOptions, 'message' | 'type'>} [options] - Additional options for the toast.
     */
    error: createToast("error"),

    /**
     * Displays a warning toast notification.
     * @param {string} message - The message to display.
     * @param {Omit<ToastOptions, 'message' | 'type'>} [options] - Additional options for the toast.
     */
    warning: createToast("warning"),

    /**
     * Displays an info toast notification.
     * @param {string} message - The message to display.
     * @param {Omit<ToastOptions, 'message' | 'type'>} [options] - Additional options for the toast.
     */
    info: createToast("info"),
};
